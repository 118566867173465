<template>
  <v-row class="ma-5">
    <v-col class="pl-1" cols="12">
      <v-btn class="pl-0" text @click="$router.go(-1)">
        <v-icon class="pr-1">mdi-chevron-left</v-icon>
        Voltar
      </v-btn>
    </v-col>
    <v-col cols="12" class="text-h6 black--text pb-0">
      Detalhes da solicitação
    </v-col>
    <v-col cols="12" class="subtitle-2 grey--text pt-0">
      Detalhes da solicitação enviada
    </v-col>
    <v-col cols="12">
      <v-form ref="formServiceRequest" v-model="validForm" lazy-validation>
        <v-row class="align-center">
          <v-col cols="12" sm="6" class="pt-0 pb-0">
            <v-row>
              <v-col cols="12"> Assunto: </v-col>
              <v-col cols="12" class="pt-0">
                <v-select
                  :items="subjects"
                  :disabled="$route.params.id ? true : false"
                  v-model="request.subject"
                  :rules="[(v) => !!v || 'Assunto é obrigatório']"
                  outlined
                  dense
                  :placeholder="$route.params.id ? '' : 'Selecione'"
                  required
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="pt-0 pb-0">
            <v-row>
              <v-col cols="12"> Prioridade: </v-col>
              <v-col cols="12" class="pt-0">
                <v-select
                  :items="priorities"
                  :disabled="$route.params.id ? true : false"
                  v-model="request.priority"
                  :rules="[(v) => !!v || 'Prioridade é obrigatório']"
                  outlined
                  dense
                  :placeholder="$route.params.id ? '' : 'Selecione'"
                  required
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="pt-0 pb-0">
            <v-row>
              <v-col cols="12"> Descrição: </v-col>
              <v-col cols="12" class="pt-0">
                <v-textarea
                  :disabled="$route.params.id ? true : false"
                  v-model="request.description"
                  :rules="[(v) => !!v || 'Descrição é obrigatório']"
                  outlined
                  dense
                  :placeholder="
                    $route.params.id ? '' : 'Descreva a necessidade do serviço'
                  "
                  required
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="pt-0 pb-0">
            <v-row class="">
              <v-col cols="12" :sm="$route.params.id ? 4 : 3" class="pb-0">
                <v-file-input
                  v-model="request.file"
                  class="white--text"
                  dense
                  prepend-icon=""
                  prepend-inner-icon="mdi-download"
                  outlined
                  :disabled="$route.params.id ? true : false"
                  :placeholder="
                    $route.params.id
                      ? !request.file
                        ? 'Nenhum arquivo anexado'
                        : request.file.name
                      : 'Anexar arquivo'
                  "
                ></v-file-input>
              </v-col>
              <v-col
                v-if="request.file"
                cols="12"
                sm="8"
                :class="{ 'pt-1': request.file }"
              >
                <a
                  :href="request.file.url"
                  v-text="request.file.name"
                  @click.prevent="
                    downloadItem(request.file.signed_id, request.file.name)
                  "
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="text-h6 black--text">
            Resposta da solicitação
          </v-col>
          <v-col cols="12" class="pb-0">
            <v-textarea
              required
              :rules="[(v) => !!v || 'Resposta é obrigatório']"
              outlined
              dense
              placeholder="Sem resposta por enquanto."
              v-model="request.answer.answer"
            >
            </v-textarea>
          </v-col>
          <v-col cols="12" sm="4" class="pt-0">
            <v-row>
              <v-col cols="12" class=""> Alterar status: </v-col>
              <v-col cols="12" class="pt-0">
                <v-select
                  :items="status"
                  v-model="request.status"
                  outlined
                  item-text="text"
                  item-value="value"
                  dense
                  :rules="[(v) => !!v || 'Status é obrigatório']"
                  required
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="2" class="">
            <v-btn
              @click="onCreateRequest"
              :disabled="!validForm"
              width="100%"
              class="primary"
            >
              salvar
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";

export default {
  data: () => ({
    status: [
      {
        text: "Em aberto",
        value: "opened",
        color: "#feda15",
      },
      {
        text: "Em análise",
        value: "under_analysis",
        color: "#096CC8",
      },
      {
        text: "Concluída",
        value: "concluded",
        color: "#00AD45",
      },
      {
        text: "Cancelada",
        value: "closed",
        color: "#CF4444",
      },
    ],
    validForm: true,
    request: {
      doctor_id: null,
      subject: "",
      description: "",
      priority: "",
      answer: {
        answer: "",
      },
      status: {
        text: "",
        color: "",
        value: "",
      },
    },
    subjects: [
      "Dashboard",
      "Alertas",
      "Perfil do paciente",
      "Novo formulário de anamnese",
      "Outros",
    ],
    priorities: ["Baixa", "Media", "Alta"],
  }),
  mounted() {
    if (this.$route.params.id) {
      if (this.$route.params.data != undefined)
        this.request = this.$route.params.data;

      if (this.$route.params.data == undefined) this.showRequest();
    }
  },
  methods: {
    async showRequest() {
      try {
        const response = await this.$axios(
          "service_requests/" + this.$route.params.id
        );

        if (response.data)
          this.request = {
            id: response.data.id,
            date: this.formatLastUpdate(response.data.updated_at),
            status: this.setStatus(response.data.status),
            priority: response.data.priority,
            description: response.data.description,
            subject: response.data.subject,
            answer: response.data.service_request_response || {},
            // file: response.data.file ? response.data.file : null,
            doctor_id: response.data.doctor_id,
          };

        console.log(this.request.status.text);
      } catch (error) {
        console.log("Get request error: ", error);
        this.$swal({
          toast: false,
          position: "center",
          showConfirmButton: true,
          icon: "error",
          title: "MIS",
          text: "Ocorreu um erro ao tentar receber a solicitação de requisição.",
        });
      }
    },
    async onCreateRequest() {
      try {
        if (!this.$refs.formServiceRequest.validate()) {
          await this.$swal({
            position: "center",
            showConfirmButton: true,
            icon: "error",
            title: "MIS",
            text: "Por favor, preencha os campos obrigatórios.",
          });

          return;
        }

        if (typeof this.request.status == "object") {
          console.log("caiu aqui");
          this.request.status = this.request.status.value;
        }

        const data = Object.assign(
          {
            service_request_response_attributes: {
              adm_id: this.request.doctor_id,
              answer: this.request.answer.answer,
            },
          },
          this.request
        );

        delete data.answer;
        delete data.id;
        delete data.date;

        await this.$axios.put("service_requests/" + this.$route.params.id, {
          service_request: data,
        });

        await this.$swal({
          position: "center",
          showConfirmButton: true,
          icon: "success",
          title: "MIS",
          text: "Resposta salva com sucesso.",
        });

        this.$router.push({ path: "/admin/services" });
      } catch (error) {
        console.log("Create request error: ", error);
        await this.$swal({
          position: "center",
          showConfirmButton: true,
          icon: "error",
          title: "MIS",
          text: "Ocorreu um erro ao tentar criar a solicitação de requisição.",
        });
      }
    },
    setStatus(value) {
      const status = {
        opened: {
          text: "Em aberto",
          value: "opened",
          color: "#feda15",
        },
        under_analysis: {
          text: "Em análise",
          value: "under_analysis",
          color: "#096CC8",
        },
        concluded: {
          text: "Concluída",
          value: "concluded",
          color: "#00AD45",
        },
        closed: {
          text: "Cancelada",
          value: "closed",
          color: "#CF4444",
        },
      };

      return (
        status[value] || {
          text: "Em abertoeee",
          value: "opened",
          color: "#feda15",
        }
      );
    },
    formatLastUpdate(date) {
      const day = date.slice(8, 10);
      const month = date.slice(5, 7);
      const year = date.slice(0, 4);
      const time = date.slice(11, 19);

      return `${day}/${month}/${year} às ${time}`;
    },
    downloadItem(signed_id, file_name) {
      this.$axios
        .get(`download/${signed_id}/${file_name}`, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data]);
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.setAttribute("download", file_name);
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.authentication.currentUser,
    }),
  },
  watch: {
    "request.status": (val) => console.log("mudou: ", val),
  },
};
</script>

<style></style>
